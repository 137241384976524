<template>
  <div class="about">
    <b-embed
      aspect="16by9"
      src="https://player.vimeo.com/video/411578717?autoplay=1&loop=1&muted=1"
      allowfullscreen
    ></b-embed>
    <div id="contentContainer" class="container text-left mt-5">
      <div class="row spacer-lg"></div>
      <div class="row mt-2">
        <div class="col-10 mx-auto text-center">
          <h1 class="about-title">Example Projects</h1>
        </div>
      </div>
      <div class="row spacer-md"></div>
      <div class="row mt-3">
        <div
          class="col-lg-6 col-md-12 col-sm-12 d-flex flex-column justify-content-start p-3"
          v-for="(project, index) in projectLinks"
          :key="index"
        >
          <a
            :href="project.link"
            class="about-page-link"
            title="go to project "
            target="_blank"
          >
            <b-img
              class="static"
              fluid
              :src="project.image"
              :alt="project.name + ' project in Scout'"
              @mouseover="project.image = project.image_gif"
              @mouseleave="project.image = project.static"
            ></b-img>
          </a>
          <div class="mt-4"></div>
          <p>
            {{ project.description }}
          </p>
        </div>
      </div>
      <div class="row spacer-md"></div>

      <hr />

      <div class="row spacer-md"></div>
      <div class="row mt-2">
        <div class="col-10 mx-auto text-center">
          <h1 class="about-title">Story of Scout</h1>
        </div>
      </div>
      <div class="row spacer-md"></div>
      <div class="row mt-3">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <p>
            Scout is a shared web platform that helps our global firm gain quick
            data-driven insights, present to clients, and engage with the
            community. Through Scout, designers and collaborators can easily
            explore and compare thousands of options, make more informed
            decisions, and enjoy the creative freedom of visualizing results in
            real time. By automating certain components of design, Scout frees
            focus for deeper development, innovation, and craft. It is software
            independent, easily accessible, and packages complex design
            scenarios into one click. Whether for two minutes or two hours,
            different types of users can coexist and get quick takeaways or
            obtain deep insights.
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <p>
            Scout is developed by
            <a href="https://ui.kpf.com" target="_blank">KPF Urban Interface</a
            >, a research and design wing within
            <a href="https://www.kpf.com/" target="_blank"
              >Kohn Pedersen Fox Architects</a
            >, that engages with the contemporary issues that impact the future
            of our buildings and cities. KPFui uses spatial and temporal urban
            data analytics for informed decision making in the design of
            buildings and cities for people while engaging with some of the most
            critical contemporary urban topics: livability, sustainability, and
            mobility.
          </p>
          <p>
            We believe that smart cities & buildings are not about drones,
            sensors or autonomous vehicles. A truly smart city should leverage
            data to design better neighborhoods and utilize technology to make
            that data transparent and publicly accessible. We think people
            should be in control of technology and not the other way around.
          </p>
        </div>
      </div>
    </div>
    <div class="row spacer-lg"></div>
    <div class="row mt-4">
      <div class="col-12 d-flex flex-column align-items-center">
        <a href="https://ui.kpf.com" target="_blank">
          <b-img :src="kpfuiLogo" height="25px" width="100%"></b-img>
        </a>
        <small class="m-2">© 2020 Kohn Pedersen Fox Associates </small>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'about',
  data() {
    return {
      kpfuiLogo: require('@/assets/images/kpfui_logo.png'),
      projectLinks: [
        {
          link: 'https://hz.scout.build',
          name: 'Hangzhou',
          description:
            'Discover hundreds of “optimal” solutions that vary with block size.',
          static: require('@/assets/images/hangzhou_screen.png'),
          image: require('@/assets/images/hangzhou_screen.png'),
          image_gif: require('@/assets/images/hangzhou_scout.gif')
        },
        {
          link: 'https://hawaii.scout.build',
          name: 'Hawaiʻi',
          description: 'Explore the future of housing development in Hawai’i.',
          static: require('@/assets/images/hawaii_screen.png'),
          image: require('@/assets/images/hawaii_screen.png'),
          image_gif: require('@/assets/images/hawaii_scout.gif')
        },
        {
          link: 'https://tampa.scout.build',
          name: 'Tampa',
          description:
            'Visualize the trade-offs among daylight, views, and efficiency.',
          static: require('@/assets/images/tampa_screen.png'),
          image: require('@/assets/images/tampa_screen.png'),
          image_gif: require('@/assets/images/tampa_scout.gif')
        },
        {
          link: 'https://daylight.scout.build',
          name: 'Daylight',
          description:
            'Detailed daylight study for informing design of a high-performing facade.',
          static: require('@/assets/images/daylight_screen.png'),
          image: require('@/assets/images/daylight_screen.png'),
          image_gif: require('@/assets/images/daylight_scout.gif')
        }
      ]
    }
  }
}
</script>
<style lang="scss">
.about {
  background: $black;
  width: 100%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  hr {
    position: relative;
    border-color: $off-white;
    width: 200vw;
    left: -100vw;
  }
  .about-title {
    font-weight: 300;
    letter-spacing: 4px;
    font-size: 28px;

    text-transform: uppercase;
  }
  p {
    color: $off-white;
    text-align: justify;
  }
}
video {
  position: relative;
  width: 100%;
  height: 100%;
  background: black;
  display: block;
  outline: none;
}

.about-page-link > img {
  border-radius: 2px;
  border: 1px solid #414141;

  &:hover {
    border: 1px solid #aaa9aa;
  }
}
</style>
